export function useNumberToCurrency(
  value: number = 0,

  usePrefix = false
) {
  const toCurrency = Number(value).toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });

  const withPrefixValue = toCurrency.substring(
    0,
    toCurrency.length - 3
  );

  if (usePrefix) return withPrefixValue;

  const withOutPrefix = withPrefixValue.substring(2);

  return withOutPrefix;
}

export function toCurrency(val: string | number) {
  // Remove char except number and dot
  val = String(val).replace(/[^0-9\.]/g, '');
  // Remove dot
  val = String(val).replace(/\./g, '');
  // To Currency
  let formattedValue = Number(val)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&.');

  // Remove decimal
  if (formattedValue?.length > 3)
    formattedValue = formattedValue.slice(
      0,
      formattedValue.length - 3
    );

  return formattedValue;
}

export function currencyToNumber(val: string) {
  return Number(String(val).replace(/\./g, ''));
  // return Number(val.toString().split('.').join(''));
}

export function toLatitude(val: string) {
  if (!val) return '';

  return val

  /// Doesnt work on safari bcs  <=   (lookback regex)
  // const latitudeDotRegex = /(?<=^.{2}$)/g;
  // const formattedValue = String(val).replace(latitudeDotRegex, '.');

  const latitudeDotRegex = /^(.{2}$)/g;
  const formattedValue = String(val).replace(latitudeDotRegex, '$1.');

  return formattedValue;
}

export function toLongitude(val: string) {
  if (!val) return '';
  
  return val

  /// Doesnt work on safari bcs  <=   (lookback regex)
  // const longitudeRegex = /(^(.*))(?<=^.{5}$)/g;
  // const formattedValue = String(val).replace(
  //   longitudeRegex,
  //   '-$1.$2'
  // );

  const longitudeRegex = /^(.{3}$)/g;
  const formattedValue = String(val).replace(
    longitudeRegex,
    '$1.'
  );

  return formattedValue;
}

export function toNpwp(val: string) {
  if (!val) return '';

  const npwpRegex = /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/;
  const formattedValue = String(val).replace(
    npwpRegex,
    '$1.$2.$3.$4-$5.$6'
  );

  return formattedValue;
}

export function toNormalDate(_date: Date | string) {
  if (!_date) return '-'

  const date = new Date(_date)
  const locale = 'id-ID'
  const options: any = { 
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  
  return date.toLocaleDateString(locale, options)
}
