const required = () => (v: any) => !!v || "Harus diisi"

const minLength = (min: any) => (v: any) => v && v.length >= min || `Minimal ${min} karakter`
const maxLength = (max: number) => (v: any) => v && v.length <= max || `Maksimal ${max} karakter`
const exactLength = (exact: number) => (v: any) => v && v.length == exact || `Harus ${exact} karakter`

const containUpper = () => (v: any) => v && /[A-Z]/.test(v) || `Harus ada huruf besar` 
const containLower = () => (v: any) => v && /[a-z]/.test(v) || `Harus ada huruf kecil` 
const containNumber = () => (v: any) => v && /[0-9]/.test(v) || `Harus ada angka`
const equalWith = (equalValue: any) => (v: any) => v && v == equalValue || `Harus sama dengan ${equalValue}`

const isEmail = () => (v: any) => v && /\S+@\S+\.\S+/.test(v) || `Harus email valid`

export {
    minLength,
    isEmail,
    maxLength,
    required,
    containLower,
    containUpper,
    containNumber,
    exactLength,
    equalWith
};
