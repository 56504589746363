import { computed } from "@vue/composition-api"

export default function useModelValue(context: any) {
    return computed({
        get: () => context.attrs.value,
        set: (v: any) => context.emit('input', v)
    })
}

export {
    useModelValue
}
