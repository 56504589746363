











import { computed, defineComponent, Ref } from '@vue/composition-api'
import KBaseInput from './KBaseInput.vue'
import useInputStyles from "@/@core/styles/inputStyles"
import useInputRules from '@/@core/helpers/inputRules'

export default defineComponent({
    components: { KBaseInput },
    name: 'KTextAreaInput',
    setup(props, { attrs }) {
        const { label, rules, ...filteredAttrs } = attrs

        const formattedRules: Ref<any[]> = computed(() => rules ? useInputRules(rules as any[], attrs.value) : [])
        
        return {
            useInputStyles,
            filteredAttrs,
            formattedRules
        }
    }
})
