












import {
  computed,
  defineComponent,
  onMounted,
  Ref
} from '@vue/composition-api';
import KBaseInput from './KBaseInput.vue';
import useInputStyles from '@/@core/styles/inputStyles';
import useInputRules from '@/@core/helpers/inputRules';
import { toCurrency } from '@/@core/helpers/formatter';

export default defineComponent({
  components: { KBaseInput },
  name: 'KCurrencyInput',
  setup(props, { emit, attrs }) {
    const { label, rules, ...filteredAttrs } = attrs;

    const formattedRules: Ref<any[]> = computed(() =>
      rules ? useInputRules(rules as any[], attrs.value) : []
    );

    onMounted(() => emit('input', toCurrency(attrs.value as string)));

    return {
      useInputStyles,
      filteredAttrs,
      formattedRules,
      toCurrency
    };
  }
});
